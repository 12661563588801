<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <!-- <div class="my-order01">
          <span>首页</span>
        </div> -->
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="management"  :userId="userId"/>
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right">
            <div class="one">{{ $t('user.meeting.purchaser') }}</div>
            <div class="two">
              <div>
                <span>{{ $t('user.company-name') }}:</span>&nbsp;
                <span>{{list.companyName}}</span>
              </div>
              <div>
                <span>{{ $t('CountryRegion') }}:</span>&nbsp;
                <span>{{list.country}}</span>
              </div>
              <div>
                <span>{{ $t('ContactName') }}:</span>&nbsp;
                <span>{{liaison.concat}}</span>
              </div>
              <div>
                <span>{{ $t('ContactEmail') }}:</span>&nbsp;
                <span>{{liaison.email}}</span>
              </div>
              <div>
                <span>{{ $t('ContactJobTitle') }}:</span>&nbsp;
                <span>{{liaison.position}}</span>
              </div>
              <div>
                <span>{{ $t('ContactMob') }}:</span>&nbsp;
                <span>{{liaison.phone}}</span>
              </div>
              <div>
                <span>{{ $t('ContactTel') }}:</span>&nbsp;
                <span>{{liaison.flPhone}}</span>
              </div>
              <div>
                <span>{{ $t('ContactFax') }}:</span>&nbsp;
                <span>{{liaison.fax}}</span>
              </div>
              <div>
                <span>{{ $t('address') }}:</span>&nbsp;
                <span>{{list.address}}</span>
              </div>
              <div>
                <span>{{ $t('Website') }}:</span>&nbsp;
                <span><a :href="list.website" target="_blank" v-if="list.website">{{list.website}}</a></span>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
   </div>
</template>

<style>
  .one{
    font-size: 24px;
    margin: 20px 0 0 20px;
    text-align: left;
  }
  .ant-card-body>.one:nth-child(1){
    margin-top: 0;
  }
  .two,.three{
    display: flex;
    flex-wrap: wrap;
  }
  .two>div{
    width: 47%;
    text-align: left;
    margin: 10px 0 10px 20px;
  }
  .three>div{
    width: 30%;
    text-align: left;
    margin: 10px 0 10px 20px;
  }
  .three>div>span:nth-child(2){
    margin-left: 10px;
  }
  .four{
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .five{
    background-color: #ddd;
    margin: 20px 0 0 20px;
    text-align: left;
  }
  .six{
    width: 200px;
    height: 200px;
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .six img{
    width: 100%;
    height: 100%;
  }
  .seven{
    width: 320px;
    height: 180px;
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .video{
    width: 320px;
    height: 180px;
  }
  .eight{
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .eight>span{
    margin-left: 10px;
  }
  .eight>span:nth-child(1){
    margin-left: 0;
  }
  .nine{
    text-align: left;
    margin: 20px 0 0 20px;  
    display: flex;
  }
  .nine>div{
    width: 180px;
    height: 180px;
  }
  .nine>div>img{
    width: 100%;
    height: 100%;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'
export default {
  name: 'PurchaserInfo',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        info: {

        },
        value: 1,
        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        },
        id: this.$route.params.id,
        userId: this.$route.params.userId,
        list: {},
        liaison: {},
        companyCategorys: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取账号信息
    getList () {
       this.$common.fetchList('/company/detail/' + this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
          this.liaison = result.liaison
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
  }
}
</script>